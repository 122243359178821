import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import opiousLogo from '../../images/opius_logo.png';
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [signInEnabled, setSignInEnabled] = useState(false);  // Toggle for enabling/disabling sign-in

  return (
    <div className="gpt3__navbar gradient__bg">
      <div className="gpt3__navbar-links_logo">
        <Link to="/">
          <img src={opiousLogo} alt="logo" />
        </Link>
      </div>
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_container">
          <Link to="/"><p>Home</p></Link>
          <Link to="/chatagent"><p>Opius Agent</p></Link>
          <Link to="/invest"><p>Investors</p></Link>
          <Link to="/contact"><p>Contact Us</p></Link>
        </div>
      </div>
      {signInEnabled && (  // Conditionally render sign-in and sign-up
        <div className="gpt3__navbar-sign">
          <Link to="/signin"> <p>Sign In</p></Link>
          <button type="button"><Link to="/signup" style={{ color: '#fff', textDecoration: 'none' }}>Sign Up</Link></button>
        </div>
      )}
      <div className="gpt3__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <Link to="/" onClick={() => setToggleMenu(false)}><p>Home</p></Link>
              <Link to="/chatagent" onClick={() => setToggleMenu(false)}><p>Opius Agent</p></Link>
              <Link to="/invest" onClick={() => setToggleMenu(false)}><p>Investors</p></Link>
              <Link to="/contact" onClick={() => setToggleMenu(false)}><p>Contact Us</p></Link>
              {signInEnabled && (  // Conditionally render sign-in and sign-up in mobile menu
                <div className="gpt3__navbar-menu_container-links-sign">
                  <Link to="/signin" onClick={() => setToggleMenu(false)}><p>Sign In</p></Link>
                  <button type="button"><Link to="/signup" onClick={() => setToggleMenu(false)} style={{ color: '#fff', textDecoration: 'none' }}>Sign Up</Link></button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;