import React, { useState } from 'react';
import axios from 'axios';
import './contact.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/contact', formData);
      if (response.status === 200) {
        setSuccessMessage('Your message has been sent successfully!');
        setFormData({ name: '', email: '', message: '' });
      }
    } catch (error) {
      setErrorMessage('There was an error sending your message. Please try again later.');
    }
  };

  return (
    <div className="container contact-us-page">
      <header className="text-center py-5">
        <h1 className="display-4">Contact Us</h1>
        <h4 className="lead">We'd love to hear from you! Send us a message using the form below.</h4>
      </header>
      
      <section className="contact-form-section py-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="5"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary">Send Message</button>
            </form>
            {successMessage && <div className="alert alert-success mt-4">{successMessage}</div>}
            {errorMessage && <div className="alert alert-danger mt-4">{errorMessage}</div>}
          </div>
        </div>
      </section>
      
      <footer className="text-center py-4">
        <p className="mb-0">Opius LLC © 2024. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default ContactUs;